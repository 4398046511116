import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hero from "../../components/hero";
import CallNowBanner from "../../components/callNowBanner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LogoSchema from "../../components/Schema/logo";
import CallIcon from "../../components/call-icon";
import Layout from "../../components/layout";
import AreaMap from "../../components/areaMap";
import { checkPropertiesForNull } from "../../utils/object";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import { GatsbyImage } from "gatsby-plugin-image";

const UPVCDoorsAndWindows = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				reviewData: wpPage(slug: { eq: "home" }) {
					reviewCountFields {
						googleReviewCount
						bwcReviewCount
						checkatradeReviewCount
					}
				}

				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				pageData: wpPage(slug: { eq: "door-and-window-lock-replacement" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					doorAndWindowLockReplacementPageFieldGroup {
						doorAndWindowLockBannerSection {
							heading
							description
							cta {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							actions {
								heading
							}
						}
						doorAndWindowLockCtaSection1 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						doorAndWindowLockCtaSection2 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						doorAndWindowLockGoogleMapSection {
							heading
							googleMap
						}
						doorAndWindowLockTextSection1 {
							description
							heading
						}
						doorAndWindowLockTextSection2 {
							description
							heading
						}
						doorAndWindowLockTwoColumnSection1 {
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						doorAndWindowLockTwoColumnSection2 {
							description
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: { seoFieldGroups, doorAndWindowLockReplacementPageFieldGroup },
		schemaLogo,
		reviewData,
		site,
		reviews,
	} = data;

	const {
		doorAndWindowLockBannerSection,
		doorAndWindowLockCtaSection1,
		doorAndWindowLockCtaSection2,
		doorAndWindowLockGoogleMapSection,
		doorAndWindowLockTextSection1,
		doorAndWindowLockTextSection2,
		doorAndWindowLockTwoColumnSection1,
		doorAndWindowLockTwoColumnSection2,
	} = doorAndWindowLockReplacementPageFieldGroup;

	const siteUrl = site.siteMetadata.siteUrl;
	const reviewCount =
		reviewData.reviewCountFields.checkatradeReviewCount +
		reviewData.reviewCountFields.googleReviewCount +
		reviewData.reviewCountFields.bwcReviewCount;
	const schemaLogoUrl = schemaLogo.publicURL;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}/what-we-do/door-and-window-lock-replacement`,
		image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
		mpn: `${siteUrl}/what-we-do/door-and-window-lock-replacement`,
		sku: `${siteUrl}/what-we-do/door-and-window-lock-replacement`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `${siteUrl}${schemaLogoUrl}`,
		name: "Seymour Locksmiths",
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${schemaLogoUrl}`,
			image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
			name: "Seymour Locksmiths",
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/our-locksmith-reviews`,
			datePublished: "2022-01-08T17:58:29+00:00",
			reviewBody:
				"What a fantastic service. I snapped the key in a lock that had been faulty for a while. Jeff arrived quickly and replaced the lock with plenty of spare keys and at a reasonable price. Nice friendly chap. I would definitely recommend and use again in the future. Thank you!",
			author: {
				"@type": "Person",
				name: "Francesca Richards",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Seymour Locksmiths",
				sameAs: `${siteUrl}`,
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: "https://www.seymour-locksmiths.co.uk",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Services",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do",
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Door and Window Lock Replacement",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do/door-and-window-lock-replacement",
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{seoFieldGroups?.productSchema?.length > 0 && (
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>
			)}

			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do/door-and-window-lock-replacement`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{doorAndWindowLockBannerSection &&
				!checkPropertiesForNull(doorAndWindowLockBannerSection, [
					"heading",
				]) && (
					<Hero
						Tag="section"
						text={
							<SafeHtmlParser
								htmlContent={doorAndWindowLockBannerSection?.description}
							/>
						}
						image={
							doorAndWindowLockBannerSection?.backgroundImage.localFile
								.childImageSharp.gatsbyImageData
						}
						heading={doorAndWindowLockBannerSection?.heading}
						hFontSize="text-4xl"
						button={doorAndWindowLockBannerSection?.cta}
						actions={doorAndWindowLockBannerSection?.actions}
					/>
				)}

			{doorAndWindowLockTextSection1 &&
				!checkPropertiesForNull(doorAndWindowLockTextSection1, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{doorAndWindowLockTextSection1?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{doorAndWindowLockTextSection1.description && (
									<div className="text-lg">
										<SafeHtmlParser
											htmlContent={doorAndWindowLockTextSection1?.description}
										/>
									</div>
								)}
							</Col>
						</Row>
					</Container>
				)}

			{doorAndWindowLockTwoColumnSection1 &&
				!checkPropertiesForNull(doorAndWindowLockTwoColumnSection1, [
					"description",
				]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="my-24 mt-below-xl-0">
								<Row className="justify-content-center">
									<Col xs={11} sm={10} xl={9} className="two-column-content">
										{doorAndWindowLockTwoColumnSection1?.description && (
											<SafeHtmlParser
												htmlContent={
													doorAndWindowLockTwoColumnSection1?.description
												}
											/>
										)}
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										doorAndWindowLockTwoColumnSection1?.image?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={doorAndWindowLockTwoColumnSection1?.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
						</Row>
					</Container>
				)}
			{doorAndWindowLockCtaSection1 &&
				!checkPropertiesForNull(doorAndWindowLockCtaSection1, ["heading"]) && (
					<CallNowBanner
						heading={doorAndWindowLockCtaSection1?.heading}
						button={doorAndWindowLockCtaSection1?.cta1}
						button2={doorAndWindowLockCtaSection1?.cta2}
					/>
				)}

			{doorAndWindowLockTextSection2 &&
				!checkPropertiesForNull(doorAndWindowLockTextSection2, ["heading"]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<h2 className="text-3xl mb-3 fw-700 lato">
									{doorAndWindowLockTextSection2?.heading}
								</h2>

								<span className="sl-underline d-block mb-5" />

								{doorAndWindowLockTextSection2?.description && (
									<SafeHtmlParser
										htmlContent={doorAndWindowLockTextSection2?.description}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}

			{doorAndWindowLockTwoColumnSection2 &&
				!checkPropertiesForNull(doorAndWindowLockTwoColumnSection2, [
					"heading",
				]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										doorAndWindowLockTwoColumnSection2?.image?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={doorAndWindowLockTwoColumnSection2?.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
							<Col xs={12} xl={6} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										<h2 className="text-3xl lato fw-700 mb-3">
											{doorAndWindowLockTwoColumnSection2?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										{doorAndWindowLockTwoColumnSection2?.description && (
											<SafeHtmlParser
												htmlContent={
													doorAndWindowLockTwoColumnSection2?.description
												}
											/>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}
			{doorAndWindowLockCtaSection2 &&
				!checkPropertiesForNull(doorAndWindowLockCtaSection2, ["heading"]) && (
					<CallNowBanner
						heading={doorAndWindowLockCtaSection2?.heading}
						button={doorAndWindowLockCtaSection2?.cta1}
						button2={doorAndWindowLockCtaSection2?.cta2}
					/>
				)}

			{doorAndWindowLockGoogleMapSection &&
				!checkPropertiesForNull(doorAndWindowLockGoogleMapSection, [
					"heading",
				]) && (
					<AreaMap
						heading={doorAndWindowLockGoogleMapSection?.heading}
						map={doorAndWindowLockGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default UPVCDoorsAndWindows;
